import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
import axios from "axios";
Vue.prototype.$axios = axios;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import '../src/assets/css/public.less';
import utils from './assets/js/utils.js';
import http from './assets/js/http.js';

import vueFilePreview from 'gxd-file-preview';

//初始化自定义插件，（pdf.js,worker.js文件建议放在本地服务器),cdn有可能不稳定
// Vue.use(vueFilePreview,{
//     pdf: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.288/build/pdf.min.js', //pdf插件
//     worker:'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.288/build/pdf.worker.min.js',//pdf.work插件
// });
import dayjs from "dayjs"
Vue.prototype.$dayjs = dayjs;

import globalData from './assets/js/global.js'   //注意文件路径，实际路径以项目目录结构为准
Vue.prototype.$globalData = globalData;
// 无限滚动
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

// 表格无限滚动
// import elTableInfiniteScroll from 'el-table-infinite-scroll';
// Vue.use(elTableInfiniteScroll);
// 表格自定义指令
Vue.directive('loadmore', {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper')
        selectWrap.addEventListener('scroll', function() {
          let sign = 0
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
          if (scrollDistance <= sign) {
            binding.value()
          }
        })
      }
    })

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts



Vue.prototype.$utils=utils;
Vue.use(http);
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
